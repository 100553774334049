import React, { Component } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { BulletinService } from "../service";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class UpdateBulletin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			bulletin_name: "",
			old_bulletin_image: null,
			bulletin_image: null,
			redirect: false
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	// function to handle file changes
	handleFileChange(event) {
		this.setState({ brand_image: event.target.files[0] });
	}

	// function to handle text change in every field
	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "bulletin_name":
				redirect.setState({ bulletin_name: value });
				break;

			default:
				break;
		}
	}

	// function to submit when the update brand button is pressed
	async onSubmit(e) {
		e.preventDefault();
		let { id } = this.props.match.params;
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append("id", id);
			fd.append("bulletin_name", this.state.bulletin_name);

			if (this.state.bulletin_image !== null) {
				fd.append("file", this.state.bulletin_image);
			}

			let data = await BulletinService.updateBulletin(fd);

			if (data) {
				this.setState({ redirect: true });
			}
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		let data = await BulletinService.showSingleBulletin(id);

		this.setState({ bulletin_name: data[0].bulletin_name });
		this.setState({ old_bulletin_file: data[0].bulletin_file });
		this.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/bulletin" />;
		} else {
			if (this.props.loading || this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Bulletin
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Bulletin
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label
														htmlFor="brand_name"
														className="control-label mb-1"
													>
														Bulletin Name
													</label>
													<input
														id="bulletin_name"
														name="bulletin_name"
														type="text"
														className="form-control"
														value={this.state.bulletin_name}
														onChange={e =>
															this.onTextChange(
																"bulletin_name",
																e.target.value
															)
														}
														aria-required="true"
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"bulletin_name",
															this.state.bulletin_name,
															"required|string"
														)}
													</div>
												</div>
												{this.state.old_bulletin_file !== null ? (
													<div className="my-4">
														{this.state.old_bulletin_file}
													</div>
												) : null}
												<div className="form-group">
													<label
														htmlFor="file"
														className="control-label mb-1"
													>
														Bulletin File Image
													</label>
													<br />
													<input
														className="form-group"
														type="file"
														name="bulletin_image"
														onChange={e =>
															this.handleFileChange(e)
														}
														accept=".doc, .docx, .pdf"
													/>
												</div>

												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Update Bulletin
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.brandReducer.message,
		loading: state.brandReducer.loading
	};
};

export default connect(mapStateToProps, {})(UpdateBulletin);
